<template>
  <v-card>
    <v-list style="margin: 1rem">
      <h4 class="panelTitle">تنظیمات رمز ارز</h4>
      <hr style="margin-top: 1rem;margin-bottom: 1rem">
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in itemsList" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="rtl">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="headers" :items="Markets" :search="search">
                <template v-slot:item.operations="{ item }">
                  <v-row style="justify-content:center;">

                    <v-btn @click="TradeDissAllow(item.id)" v-if="item.tradeAllowed === true " color="error">معاملات
                      بسته شود
                    </v-btn>
                    <v-btn @click="TradeAllow(item.id)" v-if="item.tradeAllowed === false " color="success">معاملات باز
                      شود
                    </v-btn>
                  </v-row>
                </template>
                <template v-slot:item.tradeAllowed="{ item }">
                  <i :class="[item.tradeAllowed === false ? 'flaticon-check red-check' : 'flaticon-check green-check']"></i>
                </template>
              </v-data-table>
            </v-card>
            <div>
              <Popup :show.sync="newShow" width="600px">
                <template slot="body">
                  <v-toolbar color="primary" dark class="rtl">
                    اضافه کردن کوین جدید
                  </v-toolbar>
                  <v-card-text class="rtl">
                    <slot name="body"></slot>
                    <div style="padding: 4%">
                      <p style="font-size: 15px">ایجاد کوین جدید</p>
                    </div>
                    <v-row>
                      <v-text-field label="نام فارسی" outlined v-model="persianTitle">
                      </v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field label="نام انگلیسی" outlined v-model="englishTitle"></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field v-model="fastExchangeBuyPrice" outlined label="تبادل سریع قیمت خرید"></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field outlined v-model="fastExchangeSellPrice" label="تبادل سریع قیمت فروش"></v-text-field>
                    </v-row>
                    <div class="row">
                      <v-text-field outlined label="نماد" v-model="symbol"></v-text-field>
                      <div class="col-2" style="margin-right: 30px">isInternal</div>
                      <select v-model="isInternal" class="col-4" style="background-color: #f2f3e8">
                        <option selected disabled hidden>لطفا انتخاب کنید</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </select>
                    </div>
                    <div class="row" style="margin-right: 30px;margin-top: 10px">
                      <div class="col-3"> انتخاب عکس</div>
                      <input type="file" id="picBase64" @change="convert()" accept="image/*">
                    </div>
                    <div style="margin-right: 40%">
                      <v-btn color="success" @click="addAsset()">اضافه شود</v-btn>
                    </div>
                    <hr style="border-top-color: white">
                  </v-card-text>
                </template>

              </Popup>
              <Popup :show.sync="updateShow" width="600px">
                <template slot="body">
                  <v-toolbar color="primary" dark class="rtl">
                    ویرایش کوین
                  </v-toolbar>
                  <v-card-text class="rtl">
                    <slot name="body"></slot>
                    <div style="padding: 4%">
                      <p style="font-size: 15px">ویرایش کوین </p>
                    </div>
                    <div class="row">
                    </div>
                    <v-row>
                      <v-text-field label="نام فارسی" outlined v-model="persianTitle"></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field label="نام انگلیسی " outlined v-model="englishTitle"></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field label="نماد" outlined v-model="symbol"></v-text-field>

                    </v-row>
                    <div class="row" style="margin-right: 30px;margin-top: 10px">
                      <div class="col-3"> انتخاب عکس</div>

                      <input id="askBase64" @change="UpdateConvert" type="file">
                    </div>

                    <div style="margin-right: 40%">
                      <v-btn color="success" @click="updateAsset(id)">آپدیت</v-btn>
                    </div>

                    <hr style="border-top-color: white">

                  </v-card-text>
                </template>

              </Popup>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-list>
  </v-card>
</template>

<script>
import Popup from "@/components/Popup";
import axiosApi from "@/axios";
import Vue from 'vue';

export default {
  name: "CryptoSettings",
  components: {
    Popup
  },
  data() {
    return {
      itemsList: ['مدیریت رمز ارز'],
      tab: null,
      Markets: [],
      englishTitle: '',
      persianTitle: '',
      symbol: '',
      fastExchangeBuyPrice: '',
      fastExchangeSellPrice: '',
      isInternal: '',
      base64Image: '',
      image: '',
      update: false,
      assetId: '',
      newShow: false,
      updateShow: false,
      items: ['true', 'false'],
      search: '',
      headers: [
        {
          text: 'وضعیت ',
          align: 'start',
          sortable: false,
          value: 'tradeAllowed',
        },
        { text: 'آی دی', value: 'id' },
        { text: 'نام بازار', value: 'persianTitle' },
        { text: 'نام انگلیسی', value: 'englishTitle' },
        { text: 'نماد', value: 'symbol' },
        { text: 'عملیات', value: 'operations' },
      ],
      headersNetworkAssets: [
        {
          text: 'وضعیت ',
          align: 'start',
          sortable: false,
          value: 'tradeAllowed',
        },
        { text: 'آی دی', value: 'id' },
        { text: 'کوین (asset)', value: 'asset.symbol' },
        { text: 'قابل معامله ', value: 'asset.tradeAllowed' },
        { text: 'شبکه', value: 'network.title' },
        { text: 'قابل معامله ', value: 'network.tradeAllowed' },
        { text: 'عملیات', value: 'operations' },
      ],
      headersNetwork: [
        {
          text: 'وضعیت ',
          align: 'start',
          sortable: false,
          value: 'tradeAllowed',
        },
        { text: 'آی دی', value: 'id' },
        { text: 'نام شبکه', value: 'title' },
        { text: 'رمز ارز ها', value: 'assets' },
        { text: 'عملیات', value: 'operations' },
      ],
      networkAssets: [],
      networkId: '',
      assets: [],
      networks: [],
      show: false,
      width: '500px',
      network: [],
    };
  },
  mounted() {
    this.getMarkets();
  },
  methods: {
    getMarkets() {
      axiosApi().post('api/Manage/v1/Asset/GetList')
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.Markets = data.data;
        });
    },
    TradeDissAllow(id) {
      axiosApi().post('api/Manage/v1/Asset/TradeDisallow?AssetId=' + id)
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.getMarkets();
          Vue.$toast.open({
            message: 'تغییر وضعیت رمز ارز با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    TradeAllow(id) {
      axiosApi().post('api/Manage/v1/Asset/TradeAllow?AssetId=' + id)
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.getMarkets();
          Vue.$toast.open({
            message: 'تغییر وضعیت رمز ارز با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    // getAsset(id) {
    //   this.updateShow = true
    //   axiosApi().post('api/Manage/v1/Asset/Get', {
    //     "id": id
    //   }).then(({data}) => {
    //     if (data['isSuccess'] === true)
    //       this.englishTitle = data.data.englishTitle;
    //     this.persianTitle = data.data.persianTitle;
    //     this.symbol = data.data.symbol;
    //     this.image = data.data.image;
    //     this.id = data.data.id
    //   })
    // },
    // convert() {
    //   let self = this;
    //   const file = document.querySelector('#picBase64').files[0]
    //   const reader = new FileReader();
    //   reader.addEventListener("load", function () {
    //     self.image = reader.result
    //   }, false);
    //   if (file) {
    //     reader.readAsDataURL(file)
    //   }
    // },
    // UpdateConvert() {
    //   let self = this;
    //   const file = document.querySelector('#askBase64').files[0]
    //   const reader = new FileReader();
    //   reader.addEventListener("load", function () {
    //     self.base64Image = reader.result
    //   }, false);
    //   if (file) {
    //     reader.readAsDataURL(file)
    //   }
    // },
    //




  }
};
</script>

<style scoped>

</style>